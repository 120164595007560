<template>
  <div class="t-container">
    <div class="upload">
      <!--div v-if="cid" style="display:none">{{() => showAlert()}}</div-->
      <input type="hidden" value="false" class="hidden-input"/>
      <input type="file" title="" class="drop-here">
      <div class="text text-drop">tap here<br>to <br>upload</div>
      <div class="text text-upload">uploading...</div>
      <svg class="check-wrapper" width="130" height="130">
        <polyline class="check" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
      </svg>
      <div class="shadow"></div>
    </div>
    <br>
    <div v-if="uploaded" >
      <span>Your shareable link is below!</span>
      <br>
      <div class="copy-container">
        <input type="text" :value="this.link" class="link-result" disabled>
        <button @click="copy(this.link)">Copy</button>
      </div>
    </div>
    <br>
    <div v-if="started" >
      <button class="restart" @click="restart()">Restart</button>
    </div>
  </div>
</template>

<script>
import * as web3storage from '../utils/web3storage'
const asyncCallWithTimeout = async (asyncPromise, timeLimit) => {
    let timeoutHandle;

    const timeoutPromise = new Promise((_resolve, reject) => {
        timeoutHandle = setTimeout(
            () => reject(new Error('Async call timeout limit reached')),
            timeLimit
        );
    });

    return Promise.race([asyncPromise, timeoutPromise]).then(result => {
        clearTimeout(timeoutHandle);
        return result;
    })
}

  export default {
    name: 'UploadFile',
    async mounted() {
      this.renderLandingAnimationLoadUp();
      this.detectMobileDevice();
      this.loadScript();
      this.initializeUpload();
      document.body.style.backgroundColor = "#dbe6e8";
      document.querySelector(".t-header").style.backgroundColor = "#dbe6e8";
    },
    data () {
      return {
        filelist: null,
        filename : null,
        uploaded: false,
        cid: null,
        link: '',
        start: false,
        i: 0
      }
    },
    methods: {
      async copy(s) {
        if(this.androidOrIOS() == 'ios'){          
          const linkInput = document.querySelector(".link-result");
          linkInput.focus();
          linkInput.select();

          await navigator.clipboard.writeText(s);
          alert('Copied!');
        } else if(this.androidOrIOS() == 'android') {
          if(window.ReactNativeWebView){
            window.ReactNativeWebView.postMessage(s + '~tl:android')
          } else {
            window.ReactNativeWebView.postMessage(s + '~tl:android:error')
          }
        }

        navigator.clipboard.writeText(s);
        alert('Copied!');

      },
      androidOrIOS() {
          const userAgent = navigator.userAgent;
          if(/android/i.test(userAgent)){
              return 'android';
          }
          if(/iPad|iPhone|iPod/i.test(userAgent)){
              return 'ios';
          }
      },
      restart() {
        location.reload();
      },
      showAlert: async function(){
        this.link = this.cidLink();
      },
      initializeUpload: function (){
        const fileUpload = document.querySelector(".upload");

        fileUpload.addEventListener("dragover", function() {
          this.classList.add("drag");
          this.classList.remove("drop", "done");
        });

        fileUpload.addEventListener("dragleave", function() {
          this.classList.remove("drag");
        });

        fileUpload.addEventListener("drop", startUpload, false);
        fileUpload.addEventListener("change", startUpload, false);
        fileUpload.addEventListener('change', (event) => { 
          function callback(){
            document.querySelector(".upload").classList.add("done");
            document.querySelector(".drop-here").disabled = true;
            //document.getElementsByClassName(".hidden-input")[0].value = "true";
            console.log()
            
          }
          this.onFilePicked(event.target.files, callback);
          
        });

        function startUpload() { 
          this.classList.remove("drag");
          this.classList.add("drop");
          
        }
      },
      onPickFile () {
        this.$refs.fileInput.click()
      },
      onFilePicked (_file, callback) {
        this.started = true;
        this.filename = _file[0].name
        this.onUploadFile(_file, callback);
      },
      async onUploadFile (_file,callback) {

        this.cid = await this.wontTimeout(_file);
        await this.cidLink();
        callback();
        this.uploaded = true;
      },
      wontTimeout: async(_file) =>{
        try {
            const data = await asyncCallWithTimeout(web3storage.default.storeWithProgress(_file), 10000);
            return data;
        }
        catch (err) {
            alert('Upload took too long, please try again.')
        }
      },
      async cidLink () {
        if(this.i >= 1){
          return
        }
        this.i++;

        const url = 'https://'+this.cid+'.ipfs.dweb.link/' + this.filename
        console.log(this.i)

        const response = await fetch(`https://bravo.total.dev?url=${url}`);
        const data = await response.json();
        this.link = "https://bravo.total.dev/"+data.T.toString();
      },
      async onCopyLink (textToCopy) {
        await navigator.clipboard.writeText(textToCopy)
        console.log(textToCopy)
        console.log("copied!")
      },
      detectMobileDevice: function() {
        try {
          if (document.createEvent("TouchEvent")) {
            document.querySelector(".total-header-msg").style.paddingTop =
              "calc(17px*0.9)";
            document.querySelector(".t-inner-container").style.paddingTop =
              "0px";
          }
        } catch (e) {
          return false;
        }
      },
      renderLandingAnimationLoadUp: function() {
        document.documentElement.style.setProperty("--mobilePaint", "#000");
        document
          .querySelectorAll(".t-section a")
          .forEach((attr) => (attr.style.color = "#000"));
        document.querySelector("body").style.backgroundColor = "#FFF";
      },
      collectInfo: function() {
        if (document.querySelector("#client-type").value < 1)
          return (document.querySelector("#client-type").style.border =
            "1px solid red");
        if (document.querySelector("#client-contact").value == "")
          return (document.querySelector("#client-contact").style.border =
            "1px solid red");
        if (document.querySelector("#more").value == "")
          return (document.querySelector("#more").style.border =
            "1px solid red");

        const RELEVANT_INFO = {
          CLIENT_TYPE: document.querySelector("#client-type").value,
          CLIENT_EMAIL: document.querySelector("#client-contact").value,
          CLIENT_EXTRA: document.querySelector("#more").value,
        };
        this.sendInfo(RELEVANT_INFO);
      },
      sendInfo: function(info) {
        window.Tilda.post({
          url: "https://www.tildalabs.dev/php/trie.php",
          data: { info: JSON.stringify(info), type: "propose" },
          success: function(data) {
            document.querySelector(".total-start-header-msg").style.visibility =
              "hidden";
            document.querySelector(
              ".t-start-msg-container"
            ).innerHTML = `<h1>We recieved your request!<br>For your Reference: ${data}</h1>`;
          },
          reject: function(err) {
            console.log(err);
          },
        });
      },
      captureInfo: function(info) {
        console.log(info);
      },
      loadScript: function() {
        const TILDAL_POST = document.createElement("script");
        TILDAL_POST.src = "https://www.tildalabs.dev/tools/utilities.js";
        TILDAL_POST.async = true;
        document.head.appendChild(TILDAL_POST);

        TILDAL_POST.onload = TILDAL_POST.onreadystatechange = function() {
          document.querySelector(".t-request").style.display = "block";
        };
      },
      validateEmail: function(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(String(email).toLowerCase());
      },
    },
  };
</script>

<style lang="scss" scoped>
  @font-face {
    font-family: "LotaGrotesque";
    src: url("./../assets/Lota-Grotesque-Regular.woff");
  }
  @font-face {
    font-family: "LotaGrotesque-Thin";
    src: url("./../assets/Lota-Grotesque-Thin.woff");
  }
  body{
    background-color: #dbe6e8 !important;
  }
  :root {
    --msg-mobile: ;
    --mobilePaint: #fff;
    --boundries: 32px;
  }
  .t-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10%;
  }

.upload {
  --background: #FFFFFF;
  --text-drop: #CAC8CA;
  --text-upload: #2D2C2F;
  --progress-color: #E7E5E7;
  --check-color: var(--text-upload);
  --border-radius: 30px;
  width: 300px;
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: var(--background);
    border-radius: var(--border-radius);
    transition: all .3s ease-out; 
    box-shadow: var(--shadow-x, 0px) var(--shadow-y, 1px) var(--shadow-blur, 3px) rgba(#000, 0.1);
    transform: scale(var(--scale, 1));
  }
  .drop-here {
    position: absolute;
    width: 100%;
    height: 100%;
    outline: none;
    border-radius: var(--border-radius);
    opacity: var(--opacity, 0);
    overflow: hidden;
    cursor: pointer;
    text-indent: -9999px;
    z-index: 1;
  }
  .text {
    position: absolute;
    font-size: 21px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    &.text-drop {
      color: var(--text-drop);
      opacity: var(--opacity, 1);
      transition: opacity .15s ease-out .15s;
    }
    &.text-upload {
      color: var(--text-upload);
      opacity: var(--opacity, 0);
      transition: opacity .15s ease-out;
    }
  }
  .progress-wrapper {
    position: absolute;
    .progress {
      fill: none;
      stroke: var(--progress-color);
      stroke-width: 3;
      stroke-dasharray: 722;
      stroke-dashoffset: 722;
    }
  }
  .check-wrapper {
    position: absolute;
    opacity: var(--opacity, 0);
    transform: scale(var(--scale, .9)) rotate(var(--rotate, 3deg));
    transition: transform .15s ease-in-out, opacity .15s ease-in;
    .check {
      width: 100px;
      width: 100px;
      fill: none;
      stroke: var(--check-color);
      stroke-width: 7;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 100 0;
      stroke-dashoffset: 100;
    }
  }
  .shadow {
    opacity: var(--opacity, 0);
    overflow: hidden;
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: var(--border-radius);
    filter: blur(25px);
    z-index: -1;
    transition: all .5s ease;
    &::before {
      content: '';
      position: absolute;
      top: -25%;
      left: -25%;
      height: 150%;
      width: 150%;
      background: conic-gradient(#0097e6, #6a29e9, #0097e6, #6a29e9, #9c88ff, #0097e6, #4b82d4);
      animation: shadow-animate 5s linear infinite;
    }
  }
  &.drag {
    --scale: 1.03;
    --shadow-y: 5px;
    --shadow-blur: 20px;
  }
  &.drop {
    .text {
      &.text-drop {
        --opacity: 0;
        transition: opacity .15s ease-out;
      }
      &.text-upload {
        --opacity: 1;
        transition: opacity .15s ease-out .15s;
      }
    }
    .shadow {
      --opacity: 1;
    }
    .progress-wrapper {
      opacity: var(--opacity, 1);
      transform: scale(var(--scale, 1)) rotate(var(--rotate, -90deg));
      .progress {
        animation: progress-animate 3s ease .3s forwards;
      }
    }
  }
  &.done {
    --opacity: 0;
    .text {
      &.text-upload {
        --opacity: 0;
      }
    }
    .shadow {
      --opacity: 0;
    }
    .progress-wrapper {
      --scale: .95;
      transition: transform .3s, opacity .3s;
    }
    .check-wrapper {
      --opacity: 1;
      --scale: 1;
      --rotate: 0deg;
      transition: transform .5s ease-in-out .3s, opacity .5s ease-in .3s;
      .check {
        animation: check-animate .5s ease-in-out .3s forwards;
      }
    }
  }
}

@keyframes shadow-animate {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

@keyframes progress-animate {
  0% {stroke-dashoffset: 722;}
  20% {stroke-dashoffset: 500;}
  50% {stroke-dashoffset: 322;}
  55% {stroke-dashoffset: 300;}
  100% {stroke-dashoffset: 0;}
}

@keyframes check-animate {
  0% {
    stroke-dasharray: 0 100;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 100 0;
    stroke-dashoffset: 100;
  }
}

//--- ## BASIC #############
.copy-container {
  display: flex;
  flex-direction: row-reverse;
}
/* Style the search field */
.copy-container input[type=text] {
  padding: 10px;
  font-size: 17px;
  border: 1px solid #fff;
  float: left;
  width: 80%;
  background-color: #fff;
  border-bottom-right-radius: 30px !important;
  border-top-right-radius: 30px !important;
  border-radius: var(--border-radius);
}

/* Style the submit button */
.copy-container button {
  float: left;
  width: 20%;
  padding: 10px;
  background: #2196F3;
  color: white;
  font-size: 17px;
  border: 1px solid #2196F3;
  border-left: none; /* Prevent double borders */
  cursor: pointer;
  border-bottom-left-radius: 30px !important;
  border-top-left-radius: 30px !important;
}

.copy-container button:hover {
  background: #0097e6;
}

/* Clear floats */
.copy-container::after {
  content: "";
  clear: both;
  display: table;
}
/* CSS */
.restart {
  background-color: #f6e58d;
  border-radius: 12px;
  color: #000;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 15px;
  text-align: center;
  transition: 200ms;
  width: 100%;
  box-sizing: border-box;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.restart:not(:disabled):hover,
.restart:not(:disabled):focus {
  outline: 0;
  background: #f9ca24;
  box-shadow: 0 0 0 2px rgba(0,0,0,.2), 0 3px 8px 0 rgba(0,0,0,.15);
}

.restart:disabled {
  filter: saturate(0.2) opacity(0.5);
  -webkit-filter: saturate(0.2) opacity(0.5);
  cursor: not-allowed;
}
</style>
