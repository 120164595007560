<template>
  <div class="t-container">
      <div class="t-inner-msg-container">
          <h1 class="total-header-msg">
                We combine core concepts with<br>
                powerful knowledge to build robust<br>
                experiences that engage.

          </h1>
      </div>
      <div class="t-service-container">
            <h2 class="second-msg">
                Utilizing modern technology,<br>
                we build products to improve<br>
                functionality, stay reliable, and scale<br>
                with time.
            </h2>
      </div>
      <div class="t-service-container-2">
           <h2>Our capabilties</h2>
            <div class="services">
                <br>
               <div class="research">Powerful research</div> 
               <br>
               <div class="experience">Custom U/I-U/X & CI/CD Practices</div>
               <br>
               <div class="development">Strong Development and Engineering</div>
            </div>
      </div>

     <div class="t-panel" data-color="black">
          <h2>Have something in mind?</h2>
          <div class="start-a-project-btm" @click="boundries"><span>Get Started</span></div>
          <div class="t-bottom-row">
              <div class="t-col-one">
                  <ul>
                      <li><div><img src="./../assets/logo-transparent.svg" alt="Total Developers" srcset=""></div></li>
                      <li style="margin-top:-79px">Creating it all</li>
                  </ul>
              </div>
              <div class="t-col-two">
                    <ul>
                      <li>Email - <br><span>us@total.dev</span></li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { gsap } from "gsap";

export default {
    metaInfo() {
        return {
            title: "Total.",
            meta: [
                {
                name: "description",
                content: "Creating the world's most exiting digital experiences.",
                },
                {
                name: "keywords",
                content: "research, UI, UX, user experience, user interface, studio, software engineer, software development, principals"
                },

            ],
        };
    },
    mounted(){
        this.renderLandingAnimationLoadUp();
        this.detectMobileDevice();
        document.querySelector(".t-header").style.backgroundColor = document.querySelector('body').style.backgroundColor;
    },
    beforeMount(){
        document.documentElement.style.setProperty('--mobilePaint', "#000");
    },
    methods:{
        detectMobileDevice: function(){
               try{
                   if(document.createEvent("TouchEvent")) {
                       document.querySelector(".total-header-msg").style.paddingTop = "calc(17px*0.9)";
                       document.querySelector(".t-inner-container").style.paddingTop = "0px";
                   }
                } catch(e){ 
                    return false; 
                }
        },
        boundries: function(){ 
            this.$router.push({
                name: 'Start', 
            });
            
        },
        renderLandingAnimationLoadUp:function() {
            
            gsap.from('.total-header-msg', {y: -200, duration: 1.27})
            document.documentElement.style.setProperty('--mobilePaint', "#fff");
            document.querySelector("body").style.backgroundColor = "#000";
            document.querySelectorAll(".t-section a").forEach((attr)=>attr.style.color="#fff");
            

        },
    }

}
</script>

<style lang="scss" >
@font-face {
  font-family: "LotaGrotesque";
  src: url('./../assets/Lota-Grotesque-Regular.woff');
}
@font-face {
  font-family: "LotaGrotesque-Thin";
  src: url('./../assets/Lota-Grotesque-Thin.woff');
}
:root {
  --msg-mobile: ;
  --mobilePaint: #fff;
}
.t-name{
    color: var(--mobilePaint) !important;
}
.t-panel h2{
    color: #fff;
}
.t-bottom-row {
    color: #fff;
}
.t-inner-msg-container{
    height: 100vh;
    background-color: #000;
}
.total-header-msg{
    padding-top: calc(230px*0.9);
    color: #fff;
    font-size: 72px;
    font-family: LotaGrotesque;
}
.t-service-container{
    background-color: #fff;
    padding: 12%;
    line-height: 1.2;
    color:#fff;
    font-size: 30px;
    font-family: LotaGrotesque;
    letter-spacing: .5px;
}
.t-service-container-2{
    margin: 0 auto;
    background-color: #fff;
    line-height: 1.2;
    color:#000;
    font-size:60px;
    font-family: LotaGrotesque;
    letter-spacing: .5px;
    h2 {
        margin-block-start: 0;
        margin-block-end: 0;
    }
    .research{
        font-size:47px;
        text-align: start;
    }
    .experience{
        font-size:47px;
        text-align: end;
    }
    .development{
        font-size:47px;
        text-align: start;
    }
    .services{
        padding: 12%;
    }
}
.t-panel {
    color: #000;
    background-color: #fff;
    font-size: 72px;
    font-family: LotaGrotesque;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    h2 {
        color:#000;
    }
}
.boundaries{
    padding-bottom: calc(230px*0.9);
}
.start-a-project-btm{
    padding: 12px;
    font-size: 20px;
    background-color:#535c68;
    border-radius:27px;
    display: flex;
    width: 10%;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    letter-spacing: -.5px;
    padding: calc(12px*0.9) calc(24px*0.9);
    height: calc(26px*0.9);
    text-decoration: none;
}
.start-a-project-btm span{
    color:#fff;
    font-size: 17px;
    font-family: LotaGrotesque;
    cursor: pointer;
}
.t-bottom-row{
    width:100%;
    color: #000;
    max-width: 1280px;
    display: flex;
    justify-content: space-between;
    font-family: LotaGrotesque;
    font-size: 2rem;
    ul{
        list-style: none;
        background-color: transparent;
        padding-inline-start: 0;
        li{
            text-align: start;
            background-color: transparent;
            line-height: 65px;
            span{
                font-family: LotaGrotesque-Thin;
                font-size: 24px;
            }
        }
    }
}
@media screen and (max-width: 600px) {
    .total-header-msg{
        padding-top: calc(100px*0.9);
        color: #fff;
        font-size: 40px !important;
        font-family: LotaGrotesque;
    }
    .t-service-container{
        padding-top: 100px;
        line-height: 1.2;
        color:#fff;
        font-size: 20px !important;
        font-family: LotaGrotesque;
        letter-spacing: .5px;
    }
    .t-panel {
        color: #000;
        font-size: 30px;
        font-family: LotaGrotesque;
        padding-top: calc(180px*0.9);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 100vh;
    }
    .start-a-project-btm{
        width: 50%;
    }
    .t-service-container-2{
        padding-top: 100px;
        font-family: LotaGrotesque;
        letter-spacing: .5px;
        h2{
            font-size: 30px;
        }
        .research{
            font-size: 20px;
            text-align: center;
        }
        .experience{
            font-size: 20px;
            text-align: center;
        }
        .development{
            font-size: 20px;
            text-align: center;
        }
    }
}
</style>