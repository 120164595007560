<template>
  <div class="t-container">
      <div class="t-labs-msg-container">
          <h1 class="total-labs-header-msg">
              Our research
          </h1>
      </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
export default {
    mounted(){
        
        this.renderWorkAnimationLoadUp();
        this.detectMobileDevice();
    },
    methods:{
        detectMobileDevice: function(){
               try{
                   if(document.createEvent("TouchEvent")) {
                       document.querySelector(".total-header-msg").style.paddingTop = "calc(17px*0.9)";
                       document.querySelector(".t-inner-container").style.paddingTop = "0px";
                   }
                } catch(e){ 
                    return false; 
                }
        },
        renderWorkAnimationLoadUp:function() {
            document.documentElement.style.setProperty('--mobilePaint', "#fff");
            gsap.from('.total-works-header-msg', {y: -200, duration: 1.27})
            document.querySelector("body").style.backgroundColor = "#042825";
        },
    }

}
</script>

<style lang="scss">
@font-face {
  font-family: "LotaGrotesque";
  src: url('./../assets/Lota-Grotesque-Regular.woff');
}
@font-face {
  font-family: "LotaGrotesque-Thin";
  src: url('./../assets/Lota-Grotesque-Thin.woff');
}
.total-header-msg{
    padding-top: calc(230px*0.9);
    color: #fff;
    font-size: 72px;
    font-family: LotaGrotesque;
}
.total-labs-header-msg{
    padding-top: calc(230px*0.9);
    color: #fff;
    font-size: 72px;
    font-family: LotaGrotesque;
    text-align:initial;
}
.t-inner-container{
    padding-top: 360px;
    line-height: 1.2;
    color:#fff;
    font-family: LotaGrotesque;
    font-weight: 400;
    letter-spacing: .5px;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-end;
    h2{
        text-align: initial;
        float: right;
        font-size: 3rem;
    }
    a{
        color: #fff;
        text-decoration: underline;
        text-decoration-color:#748f8c;

    }
    .t-our-approach{
        font-size: 2rem;
    }
    a:hover{
        text-decoration-color:#fff;
    }
}
@media screen and (max-width: 600px) {
    .total-labs-header-msg{
        padding-top: 10px;
        font-size: 35px;
    }
    .t-inner-container h2{
        font-size: 2em;
        
    }
}
</style>