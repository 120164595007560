import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from "vue-meta"
import Landing from '../views/Landing.vue'
import Work from '../views/Work.vue'
import Services from '../views/Services.vue'
import Labs from '../views/Labs.vue'
import Start from '../views/Start.vue'
import HelitronScanner from '../views/HelitronScanner.vue'
import Medical from '../views/Medical.vue'
import NotFound from '../views/404.vue';
import Empty from '../views/Empty.vue';
import Exo from '../views/Exo.vue';

Vue.use(VueRouter)
Vue.use(Meta)
const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/Accounts',
    name: 'Accounts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Accounts.vue')
  },
  {
    path: '/ai-assistant',
    name: 'Assistant',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Assistant.vue')
  },
  {
    path: '/Work',
    name: 'Work',
    component: Work
  },
  {
    path: '/Services',
    name: 'Services',
    component: Services
  },
  {
    path: '/Labs',
    name: 'Labs',
    component: Labs
  },
  {
    path: '/start-a-project',
    name: 'Start',
    component: Start
  },
  {
    path: '/payment-gateway',
    name: 'Payment',
    component: () => import('../views/Payment.vue')
  },
  {
    path: '/Projects',
    component: Empty,
    children:[{
      name:'HeltronScanner',
      path:'HelitronScanner',
      component: HelitronScanner
    },{
      name: 'Medical',
      path: 'Medical',
      component: Medical
    }]
  },
  {
    path: '/Exo',
    name: 'Exo',
    component: Exo
  },
  { path: "*", component: NotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
