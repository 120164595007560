<template>
  <div class="t-header">
      <div class="t-header-container">
          <router-link to="/" class="t-name">total.</router-link>
          <div class="t-section">
            <router-link to="/work" id="t-app">work </router-link>
            <router-link to="/services" id="t-app">services</router-link>
            <a href="https://tildalabs.dev/">labs</a>
            <router-link to="/accounts" id="t-app">accounts</router-link>
            <a href="https://total.dev/v5/" class="start-a-project"><span>Login</span></a>
          </div>
        <div class="menu-collapsed" @click="openMobileMenu">
            <div class="t-name-mobile">total.</div>
            <div class="bar" ></div>
            <nav id="nav">
                <ul>
                    <li><a href="https://total.dev/v5/">Login</a></li>
                    <li><router-link to="/Work">Work</router-link></li>
                    <li><router-link to="/Services">Services</router-link></li>
                    <li><a href="https://tildalabs.dev">Labs</a></li>
                    <li><router-link to="/Accounts">Accounts</router-link></li>  
                </ul>
            </nav>
        </div>
      </div>

  </div>
</template>

<script>
import { gsap } from "gsap";
export default {
    mounted(){
        gsap.from('.t-header', {y: -40, duration: 1.27})
        document.querySelector(".t-header").style.backgroundColor = document.querySelector('body').style.backgroundColor;
        console.log(document.querySelector('body').style.backgroundColor);
    },
    methods:{
        openMobileMenu(){
            var menuElement = document.querySelector(".menu-collapsed");
            let total = document.getElementsByClassName("t-name")[0];
            let totalMobile = document.getElementsByClassName("t-name-mobile")[0];
            var mobileBar = document.querySelector(".bar");
            menuElement.classList.toggle("menu-expanded");
            if(mobileBar.classList == "bar re-open"){
                total.style.color = "#fff";
                totalMobile.style.display = "none";
                mobileBar.classList.remove("re-open");
                return this.removeContainer(true);
            }
            total.style.color = "#000";
            totalMobile.style.display = "block";
            mobileBar.classList.add("re-open");
            return this.removeContainer(false);
        },
        removeContainer(x){
            if(this.find(".t-container")){
                if(x){
                    return document.querySelector(".t-container").style.display = "block";
                }
                return document.querySelector(".t-container").style.display = "none";
            }
        },
        find(params){
            if(document.querySelector(params)){
                return true;
            }
            return false;
        }
    }

}
</script>

<style lang="scss">
@font-face {
  font-family: "LotaGrotesque";
  src: url('./../assets/Lota-Grotesque-Regular.woff');
}

.t-header-container{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    color:white;
    max-width: 1280px;
}
.t-section{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.menu-collapsed{
    display: none;
}
.t-name{
    font-size: 47px;
    font-family: LotaGrotesque;
    color: white;
    text-decoration: none;
    padding: 12px;
}
.t-name-mobile{
    font-size: 47px;
    font-family: LotaGrotesque;
    color: black;
    text-decoration: none;
    text-align: start;
    display: none;
}
.nav{
    margin-top:70px;
}
.t-section a{
    margin: 0 calc(15px*0.9);
    padding: calc(8px*0.9) calc(5px*0.9);
    font-size: 20px;
    color: white;
    letter-spacing: -.5px;
    font-family: LotaGrotesque;
    text-decoration: none;
    position: relative;

}
.t-section a#t-app:hover {
    color: #fff;
}
.t-section a#t-app::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -10px;
    left: 0;
    background-color: #fff;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}
.t-section a#t-app:hover::before {
    transform: scaleY(1);
}
.t-section .start-a-project{
    padding: 12px;
    font-size: 20px;
    background-color:#535c68;
    border-radius:27px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    letter-spacing: -.5px;
    padding: calc(12px*0.9) calc(24px*0.9);
    height: calc(26px*0.9);
}

.start-a-project span{
    color:#fff;
    font-size: 17px;
    font-family: LotaGrotesque;
}
nav li{
    background-color: #fff;
    color:#000;
    line-height: 140px;
}
ul li:nth-child(1){
    margin-top: 30px;
}
@media screen and (max-width: 600px) {
    .t-header{
        margin: 1em;
    }
    .t-section{
        display: none;
    }
    .b-a {
        transition: all .25s;
        content: "";
        position: absolute;
        right: 0;
        height: 2px;
        width: 30px;
        background-color: rgba(255, 255, 255, 0.95);
        }
        .menu-collapsed{
            display: block;
            transition: all .25s;
            position: fixed;
            top: 21px;
            right:0px;
            height: 36px;
            width: 36px;
            z-index: 1;
            cursor: pointer;
        ul {
            transition: all 0s;
            position: fixed;
            left: -9000px;
        }
    }
    .bar {
        @extend .b-a;
        float:right;
        right: 1px;
        z-index: 10000000;
        top: 10px;
        width: 21px;
        background-color: antiquewhite;
        &:before {
            @extend .b-a;
            top: -8px;
        }
        &:after {
            @extend .b-a;
            top: 8px;
        }
    }
    .b-a-expanded {
        transition: all .25s;
        top: -0px;
        }
        .menu-expanded {
            transition: all .25s;
            text-align: center;
            height:98vh;
            width: 100%;
            border-radius: 0px;
            top: 0;
            right: 0;
            background-color: #fff;
        ul {
            transition: all 0s;
            position: relative;
            left: 0;
            z-index: 2;
            list-style: none;
            padding-inline-start: initial;
            margin-block-start: initial;
        }
        a {
            transition: all .15s;
            text-decoration: none;
            font-size: 1.5em;
            padding: 5px;
            display:block;
            &:hover {
                background-color:rgba(white, .8);
                transition: all .15s;
                letter-spacing: 2px;
                color:#333;
                border: 1px solid rgba(255, 255, 255, .15);
            }
        }
        .bar {
            background-color: transparent;
            transition: all .25s;
            margin: 6px;
            &:before {
                @extend .b-a-expanded;
                transform: rotate(45deg);
                background-color:#000;
                z-index: 100;
            }
            &:after {
                @extend .b-a-expanded;
                transform: rotate(-45deg);
                background-color:#000;
                z-index: 100;
            }
        }
        .re-open{
            top:21px;
            width: 100px;
            height:70px;
            background-color: transparent
        }
    }
}


</style>