<template>
  <div class="t-container">
    <div class="t-start-msg-container">
      <div class="message">
        <h1 class="total-start-header-msg">Tell us more.</h1>
        <p class="total-start-extra-info-msg">
          This will help us create a more personalized solution.
        </p>
      </div>
      <br />

      <div class="start-container">
        <button class="total-start-btn">Start</button>
      </div>

      <div class="auto-scroll-form">
        <div class="step">
          <h2>1. What area do you need help in.</h2>
          <div class="answer">
            <label for=""
              >Websites
              <input
                type="radio"
                name="client-type"
                id="client-type"
                value="websites"
              />
            </label>
            <br />
            <label for="">
              M L / A I
              <input
                type="radio"
                name="client-type"
                id="client-type"
                value="ai"
              />
            </label>
            <br />
            <label for=""
              >Crypto
              <input
                type="radio"
                name="client-type"
                id="client-type"
                value="crypto"
                checked
              />
            </label>
          </div>
        </div>
        <div class="step">
          <h2>2. How can we contact you?</h2>
          <div class="answer" style="width: 50%">
            <input type="text" name="client-contact" id="client-contact" />
          </div>
        </div>
        <div class="step">
          <h2>3. Where did you hear about us?</h2>
          <div class="answer" style="width: 50%">
            <input type="text" name="client-referal" id="more" />
          </div>
        </div>
        <div class="t-request" @click="collectInfo">
          <span>Request a Proposal</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    mounted() {
      this.renderLandingAnimationLoadUp();
      this.detectMobileDevice();
      this.loadScript();
      document.querySelector(
        ".t-header"
      ).style.backgroundColor = document.querySelector(
        "body"
      ).style.backgroundColor;
      if (this.$route.params.id == 123) {
        location.reload();
      }
      function fadeOut(el) {
        el.style.opacity = 1;

        (function fade() {
          if ((el.style.opacity -= 0.1) < 0) {
            el.style.display = "none";
          } else {
            requestAnimationFrame(fade);
          }
        })();
      }

      function fadeIn(el, display) {
        el.style.opacity = 0;
        el.style.display = display || "block";

        (function fade() {
          var val = parseFloat(el.style.opacity);
          if (!((val += 0.1) > 1)) {
            el.style.opacity = val;
            requestAnimationFrame(fade);
          }
        })();
      }
      document
        .querySelector(".total-start-btn")
        .addEventListener("click", function() {
          fadeOut(this);
          fadeOut(document.querySelector(".message"))
          fadeIn(document.querySelector(".auto-scroll-form"));
        });
    },
    methods: {
      detectMobileDevice: function() {
        try {
          if (document.createEvent("TouchEvent")) {
            document.querySelector(".total-header-msg").style.paddingTop =
              "calc(17px*0.9)";
            document.querySelector(".t-inner-container").style.paddingTop =
              "0px";
          }
        } catch (e) {
          return false;
        }
      },
      renderLandingAnimationLoadUp: function() {
        document.documentElement.style.setProperty("--mobilePaint", "#000");
        document
          .querySelectorAll(".t-section a")
          .forEach((attr) => (attr.style.color = "#000"));
        document.querySelector("body").style.backgroundColor = "#FFF";
      },
      collectInfo: function() {
        if (document.querySelector("#client-type").value < 1)
          return (document.querySelector("#client-type").style.border =
            "1px solid red");
        if (document.querySelector("#client-contact").value == "")
          return (document.querySelector("#client-contact").style.border =
            "1px solid red");
        if (document.querySelector("#more").value == "")
          return (document.querySelector("#more").style.border =
            "1px solid red");

        const RELEVANT_INFO = {
          CLIENT_TYPE: document.querySelector("#client-type").value,
          CLIENT_EMAIL: document.querySelector("#client-contact").value,
          CLIENT_EXTRA: document.querySelector("#more").value,
        };
        this.sendInfo(RELEVANT_INFO);
      },
      sendInfo: function(info) {
        window.Tilda.post({
          url: "https://www.tildalabs.dev/php/trie.php",
          data: { info: JSON.stringify(info), type: "propose" },
          success: function(data) {
            document.querySelector(".total-start-header-msg").style.visibility =
              "hidden";
            document.querySelector(
              ".t-start-msg-container"
            ).innerHTML = `<h1>We recieved your request!<br>For your Reference: ${data}</h1>`;
          },
          reject: function(err) {
            console.log(err);
          },
        });
      },
      captureInfo: function(info) {
        console.log(info);
      },
      loadScript: function() {
        const TILDAL_POST = document.createElement("script");
        TILDAL_POST.src = "https://www.tildalabs.dev/tools/utilities.js";
        TILDAL_POST.async = true;
        document.head.appendChild(TILDAL_POST);

        TILDAL_POST.onload = TILDAL_POST.onreadystatechange = function() {
          document.querySelector(".t-request").style.display = "block";
        };
      },
      validateEmail: function(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(String(email).toLowerCase());
      },
    },
  };
</script>

<style lang="scss">
  @font-face {
    font-family: "LotaGrotesque";
    src: url("./../assets/Lota-Grotesque-Regular.woff");
  }
  @font-face {
    font-family: "LotaGrotesque-Thin";
    src: url("./../assets/Lota-Grotesque-Thin.woff");
  }
  :root {
    --msg-mobile: ;
    --mobilePaint: #fff;
    --boundries: 32px;
  }
  .line{
    height: 200px;
    width: 1px
  }
  .total-start-header-msg {
    padding-top: calc(230px * 0.9);
    width: 70%;
    margin: 0 auto;
    color: #000;
    font-size: 72px;
    font-family: LotaGrotesque;
    text-align: initial;
  }
  .total-start-btn {
    font-family: LotaGrotesque;
    cursor: pointer;
    font-size: 1em;
    line-height: 1.5em;
    box-sizing: border-box;
    background-color: transparent;
    border: 1px solid #000;
    color: rgb(40, 40, 61);
    text-align: left;
    border-radius: 0px;
    padding: 0.6075em 0.75em;
    margin: 0px;
    transition: color 0.15s ease-in-out 0s, background-color 0.4s ease-in-out 0s,
      border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s,
      opacity 0.15s ease-in-out 0s;
    color: #000;
    &:hover {
      background-color: rgba(12, 35, 95, 0.58);
    }
  }
  .start-container {
    text-align: left;
    width: 69%;
    margin: 0 auto;
  }
  .auto-scroll-form {
    display: none;
    font-family: LotaGrotesque;
    margin: 0 auto;
    height: 400px;
    width: 70%;
    overflow-y: scroll;
    padding: 5%;
    .step {
      margin-bottom: 100px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    #more {
      padding: 10px 20px;
      width: 100%;
    }
    #client-contact {
      padding: 10px 20px;
      width: 100%;
    }
    label {
      display: inline-block;
      padding: 10px 20px;
      font-family: sans-serif, Arial;
      font-size: 16px;
      border: 1px solid #444;
      border-radius: 4px;
      background-color: transparent;
      transition: color 0.15s ease-in-out 0s,
        background-color 0.4s ease-in-out 0s, border-color 0.15s ease-in-out 0s,
        box-shadow 0.15s ease-in-out 0s, opacity 0.15s ease-in-out 0s;
      &:hover {
        color: #fff;
        background-color: rgba(12, 35, 95, 0.58);
      }
      input:checked {
        background-color: #444;
      }
    }
  }
  .total-start-extra-info-msg {
    margin: 0 auto;
    width: 69%;
    text-align: left;
    font-family: LotaGrotesque;
  }
  .answer {
    display: inline-grid;
  }
  .total-start-btn .info-container {
    font-family: LotaGrotesque;
    font-size: 30px;
    width: 70%;
    margin: 0 auto;
    text-align: start;
    line-height: 2.9em;
    input {
      font-size: 20px;
      width: 200px;
      height: 40px;
      background-color: rgba($color: #000000, $alpha: 0.06);
      border: 1px solid gainsboro;
    }
    select {
      width: 200px;
      height: 40px;
      font-size: 20px;
      font-family: LotaGrotesque;
      background-color: rgba($color: #000000, $alpha: 0.06);
      border: 1px solid gainsboro;
      -webkit-writing-mode: horizontal-tb !important;
      text-rendering: auto;
      letter-spacing: normal;
      word-spacing: normal;
      text-transform: none;
      text-indent: 0px;
      text-shadow: none;
      display: inline-block;
      text-align: start;
      appearance: textfield;
      background-color: -internal-light-dark(
        rgb(255, 255, 255),
        rgb(59, 59, 59)
      );
      -webkit-rtl-ordering: logical;
      cursor: text;
      margin: 0em;
      padding: 1px 2px;
      border-image: initial;
      option {
        font-family: LotaGrotesque;
        border: none;
      }
    }
  }
  .t-request {
    cursor: pointer;
    display: none;
    margin-top: var(--boundries);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .t-request span {
    background-color: rgba($color: #000000, $alpha: 0.06);
    width: 70%;
    padding: 12px;
    border-radius: 17px;
    margin: 0 auto;
    color: #000;
    font-size: 27px;
    font-family: LotaGrotesque;
    text-align: initial;
    text-align: center;
    text-decoration: none;
    position: relative;
  }
  .t-request span:hover {
    color: #000;
  }
  .t-request span::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -10px;
    left: 0;
    background-color: #000;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
  }
  .t-request span:hover::before {
    transform: scaleY(1);
  }
  @media screen and (max-width: 600px) {
    .total-start-header-msg {
      padding-top: calc(20px * 0.9);
      text-align: center;
    }
    .info-container {
      line-height: 2.9em;
      width: 100%;
    }
    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 100px;
    }
    .auto-scroll-form {
      align-items: center;
      display: none;
      flex-direction: column;
    }
    #client-contact {
      width: inherit !important;
    }
    #more {
      width: inherit !important;
    }
    .t-request span {
      font-size: 17px;
    }
  }
</style>
