<template>
  <div id="main">
    <div class="fof">
          <h1>Error 404</h1>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
  @font-face {
    font-family: "LotaGrotesque-Thin";
    src: url("./../assets/Lota-Grotesque-Thin.woff");
  }
  body {
    background-color: #dbe6e8;
  }
  #main{
      display: table;
      width: 100%;
      margin-top: 10%;
      text-align: center;
  }
  .fof{
      display: table-cell;
      vertical-align: middle;
      
  }

  .fof h1{
      font-size: 50px;
      display: inline-block;
      padding-right: 12px;
      animation: type .5s alternate infinite;
      font-family: "LotaGrotesque-Thin";
  }

  @keyframes type{
      from{box-shadow: inset -3px 0px 0px #888;}
      to{box-shadow: inset -3px 0px 0px transparent;}
  }
</style>