import { render, staticRenderFns } from "./Exo.vue?vue&type=template&id=84db1fac&scoped=true&"
import script from "./Exo.vue?vue&type=script&lang=js&"
export * from "./Exo.vue?vue&type=script&lang=js&"
import style0 from "./Exo.vue?vue&type=style&index=0&id=84db1fac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84db1fac",
  null
  
)

export default component.exports