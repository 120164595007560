<template>
    <div class="t-scroll-indicator">
        <form>
            <label class="radio">
                <span class="radio__input">
                    <input type="radio" name="slider" v-on:click="scrollIntoView($event)" id="Slide1" checked>
                    <span class="radio__control"></span>
                </span>
            </label>
            <label class="radio">
                <span class="radio__input">
                    <input type="radio" name="slider" v-on:click="scrollIntoView($event)" id="Slide2" ><!--v-on:click="scrollIntoView($event)"-->
                    <span class="radio__control"></span>
                </span>
            </label>
            <label class="radio">
                <span class="radio__input">
                    <input type="radio" name="slider" v-on:click="scrollIntoView($event)" id="Slide3">
                    <span class="radio__control"></span>
                </span>
            </label>
        </form>
    </div>
</template>

<script>
export default {

    mounted(){
        window.onscroll = function() {scrollProgress()};
        
        function scrollProgress() {
            var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
            var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            var scrolled = (winScroll / height) * 100;
            if(scrolled <= 33){
                document.getElementById("Slide1").checked = true;
            } else if(scrolled > 33 && scrolled <= 64) {
                document.getElementById("Slide2").checked = true;
                document.getElementById("Slide1").checked = false;
            } else {
                document.getElementById("Slide2").checked = false;
                document.getElementById("Slide1").checked = false;
                document.getElementById("Slide3").checked = true;
            }
        }
    },
     methods: {
        scrollIntoView: function (event){
            switch (event.target.id) {
                case "Slide1":
                    return window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                case "Slide2":
                    return window.scrollTo({
                        top: (document.documentElement.scrollHeight - document.documentElement.clientHeight)/2,
                        left: 0,
                        behavior: 'smooth'
                    });
                case "Slide3":
                    return window.scrollTo({
                        top: document.documentElement.scrollHeight - document.documentElement.clientHeight,
                        left: 0,
                        behavior: 'smooth'
                    });
            }

        }
    }

}
</script>

<style lang="scss">
.t-scroll-indicator{
    display: flex;

    form{
        z-index: 100000000000;
        position: fixed;
        right:0;
        top:50vh;
        padding: 22px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        :root {
            --color: rebeccapurple;
        }

        *,
        *:before,
        *:after {
        box-sizing: border-box;
        }

        .radio {
            display: grid;
            grid-template-columns: min-content auto;
            grid-gap: 0.5em;
            padding:12px;
            font-size: 1rem;
            cursor: pointer;
            color: var(--color);
            &:focus-within {
                .radio__label {
                transform: scale(1.05);
                opacity: 1;
                }
            }
        }

        .radio__label {
            line-height: 1;
            transition: 180ms all ease-in-out;
            opacity: 0.8;
        }

        .radio__input {
            display: flex;

        input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked + .radio__control {
            box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em currentColor;
            }
        }
        }

        .radio-gradient input:checked + .radio__control {
            background: radial-gradient(currentcolor 50%, rgba(255, 0, 0, 0) 51%);
        }

        .radio-before {
            .radio__control {
                display: grid;
                place-items: center;
            }

            input + .radio__control::before {
                content: "";
                width: 0.5em;
                height: 0.5em;
                box-shadow: inset 0.5em 0.5em currentColor;
                border-radius: 50%;
                transition: 180ms transform ease-in-out;
                transform: scale(0);
            }

            input:checked + .radio__control::before {
                transform: scale(1);
            }
        }

        .radio__control {
            display: block;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            border: 0.1em solid currentColor;
            transform: translateY(-0.05em);
        }
    }
}
@media screen and (max-width: 600px) {
    .t-scroll-indicator{
        visibility: hidden;
    }
}
</style>