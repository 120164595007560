<template>
  <div class="hero-work-container">
      <img src="https://www.tildalabs.dev/assets/project-helitron-scanner.png" alt="" srcset="">
          <div class="t-panel" data-color="white">
      <h2>
        Have something in mind?<br />
        Let's get it done.
      </h2>
      <div class="start-a-project-btm" @click="boundries">
        <span>Start a Project</span>
      </div>
      <div class="t-bottom-row">
        <div class="t-col-one">
          <ul>
            <li>
              <div>
                <img
                  src="./../assets/logo-transparent.svg"
                  alt="Total Developers"
                  srcset=""
                />
              </div>
            </li>
            <li style="margin-top: -79px">Creating it all</li>
          </ul>
        </div>
        <div class="t-col-two">
          <ul>
            <li>Email - <br /><span>us@total.dev</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
export default {
    mounted(){     
        this.renderLandingAnimationLoadUp();
        document.documentElement.style.setProperty('--mobilePaint', "#000");
        document.querySelector(".t-header").style.backgroundColor = "#c9f9d4";

    },
    methods: {
        renderLandingAnimationLoadUp:function() {
            document.querySelectorAll(".t-section a").forEach((attr)=>attr.style.color="#000");
            document.querySelector(".t-header-container a").style.color = "#000";
        },
    }
}
</script>

<style lang='scss'>
body{
    margin: 0;
    padding: 0;
    background-color: #c9f9d4;
}
.hero-work-container{

    img{
        width: 100%;
        overflow: hidden;
    }
}
</style>