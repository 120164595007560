import {
    Web3Storage
  } from 'web3.storage/dist/bundle.esm.min.js'
  
  let cid_val = '3'
  export default {
    cid_val,
    getAccessToken() {
      // Set the VUE_APP_WEB3STORAGE_TOKEN
      //environment variable before you run your code.
      return process.env.VUE_APP_WEB3STORAGE_TOKEN
    },
  
    makeStorageClient() {
      return new Web3Storage({
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweEIyMkY5M2UzMDg3ZWMwZTlBZDQxMDQyQzQxNWRDN2FEOTI0NjUwOEUiLCJpc3MiOiJ3ZWIzLXN0b3JhZ2UiLCJpYXQiOjE2NTQyNjgzMTMzOTYsIm5hbWUiOiJleG8ifQ.qjKI75dkHdlCl6GSyTfQgVp8v-61tgb5IL4-p3m50IU'
      })
    },
  
    async storeWithProgress(files) {
      // TODO: Name the cab of files?
      // Now it is named based on date/time

      // show the root cid as soon as it's ready
      const onRootCidReady = cid => {
          console.log('uploading files with cid:', cid)
      }
  
      // when each chunk is stored, update the percentage complete and display
      const totalSize = files[0].size
      let uploaded = 0
  
      const onStoredChunk = size => {
        uploaded += size
        const pct = totalSize / uploaded
        console.log(`Uploading... ${pct.toFixed(2)}% complete`)
        // TODO: let's get this to show in browser & update!
      }
  
      // makeStorageClient returns an authorized Web3.Storage client instance
      const client = this.makeStorageClient()
  
      // client.put will invoke our callbacks during the upload
      // and return the root cid when the upload completes
      return client.put(files, {
        onRootCidReady,
        onStoredChunk
      })
    }
  }