<template>
  <div class="t-container">
    <div class="t-inner-msg-container">
      <h1 class="total-header-msg">
        Creating <br />digital solutions for all.<br><router-link to="/start-a-project" class="start-a-project-call-to"><span>Request a Proposal</span></router-link>
      </h1>
      <br>

    </div>
    <div class="t-inner-container" style="padding-top: 180px">
      <h2>
        Creating online experiences that matter for<br />
        complex businesses, needing a custom approach.<br />
        <br /><span class="t-our-approach"
          ><router-link to="/Services">Take a look</router-link></span
        >
      </h2>
    </div>
    <div class="t-panel" data-color="white">
      <h2>
        Have something in mind?
      </h2>
      <div class="start-a-project-btm" @click="boundries">
        <span>Get Started</span>
      </div>
      <div class="t-bottom-row">
        <div class="t-col-one">
          <ul>
            <li>
              <div>
                <img
                  src="./../assets/logo-transparent.svg"
                  alt="Total Developers"
                  srcset=""
                />
              </div>
            </li>
            <li style="margin-top: -79px">Creating it all</li>
          </ul>
        </div>
        <div class="t-col-two">
          <ul>
            <li>Email - <br /><span>us@total.dev</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  metaInfo() {
    return {
      title: "Total.",
      meta: [
        {
          name: "description",
          content: "Creating the world's most exiting digital experiences.",
        },
        {
          name: "keywords",
          content: "how to design a web site, what is responsive web design, what is a web host, web developer"

        }
      ],
    };
  },
  mounted() {
    this.renderLandingAnimationLoadUp();
    this.detectMobileDevice();
    document.querySelector(".t-header").style.backgroundColor = document.querySelector("body").style.backgroundColor;
  },
  beforeMount() {
    document.documentElement.style.setProperty("--mobilePaint", "#000");
  },
  methods: {
    detectMobileDevice: function () {
      try {
        if (document.createEvent("TouchEvent")) {
          document.querySelector(".total-header-msg").style.paddingTop =
            "calc(17px*0.9)";
          document.querySelector(".t-inner-container").style.paddingTop = "0px";
        }
      } catch (e) {
        return false;
      }
    },
    videoHandler: function () {
      document.querySelector("video").style.visibility = "hidden";
    },
    boundries: function () {
      this.$router.push({
        name: "Start",
        params: { id: "123" },
      });
    },
    renderLandingAnimationLoadUp: function () {
      gsap.from(".total-header-msg", { y: -200, duration: 1.27 });
      document.documentElement.style.setProperty("--mobilePaint", "#fff");
      document.querySelector("body").style.backgroundColor = "#000";
      document
        .querySelectorAll(".t-section a")
        .forEach((attr) => (attr.style.color = "#fff"));

      gsap.registerPlugin(ScrollTrigger);

      //const container = document.querySelector("body");

      /*let tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".t-inner-container",
          pin: false,
          start: "top center",
          scrub: true,
          end: "bottom bottom",
          onEnter: () => {
            tl.to(container, { duration: 1.0, backgroundColor: "#FFF" });
          },
          onLeaveBack: () => {
            gsap.to(container, { duration: 1.0, backgroundColor: '#000'})
          },
        },
  
      });

      
      tl.kill();*/
    },
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "LotaGrotesque";
  src: url("./../assets/Lota-Grotesque-Regular.woff");
}
@font-face {
  font-family: "LotaGrotesque-Thin";
  src: url("./../assets/Lota-Grotesque-Thin.woff");
}
:root {
  --msg-mobile: ;
  --mobilePaint: #fff;
}

.start-a-project-call-to{
    padding: 12px;
    font-size: 20px !important;
    text-decoration: none;
    font-weight: 100;
    background-color:#535c68;
    border-radius:27px;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    letter-spacing: -.5px;
    padding: calc(12px*0.9) calc(24px*0.9);
    height: calc(26px*0.9);
}
.start-a-project-call-to span{
    color:#fff;
    font-size: 17px;
    font-family: LotaGrotesque;
}
.t-name {
  color: var(--mobilePaint) !important;
}
.total-header-msg {
  padding-top: calc(230px * 0.9);
  color: #fff;
  font-size: 72px;
  font-family: LotaGrotesque;
  a{
    font-size: 35px;
    color:#fff;
  }
}

.t-inner-container {
  padding: 11px;
  padding-top: 360px;
  line-height: 1.2;
  color: #fff;
  font-family: LotaGrotesque;
  font-weight: 400;
  letter-spacing: 0.5px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  h2 {
    text-align: initial;
    float: right;
    font-size: 3rem;
  }
  a {
    color: #000;
    text-decoration: underline;
    text-decoration-color: #748f8c;
  }
  .t-our-approach {
    font-size: 2rem;
  }
  a:hover {
    text-decoration-color: #fff;
  }
}
.t-inner-msg-container{
  height: 100vh;
  background-color: #000;
}
.t-inner-container{
  background-color: #fff;
}
.t-panel {
  background-color: #fff;
  color: #000;
  font-size: 72px;
  font-family: LotaGrotesque;
  padding-top: calc(230px * 0.9);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}
.start-a-project-btm {
  padding: 12px;
  font-size: 20px;
  background-color: #535c68;
  border-radius: 27px;
  display: flex;
  width: 10%;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  letter-spacing: -0.5px;
  padding: calc(12px * 0.9) calc(24px * 0.9);
  height: calc(26px * 0.9);
  text-decoration: none;
}
.start-a-project-btm span {
  color: #fff;
  font-size: 17px;
  font-family: LotaGrotesque;
  cursor: pointer;
}
.t-bottom-row {
  width: 100%;
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
  font-family: LotaGrotesque;
  font-size: 2rem;
  ul {
    list-style: none;
    background-color: transparent;
    padding-inline-start: 0;
    li {
      text-align: start;
      background-color: transparent;
      line-height: 65px;
      span {
        font-family: LotaGrotesque-Thin;
        font-size: 24px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .total-header-msg {
    font-size: 65px !important;
  }
  .t-inner-container h2 {
    font-size: 2em;
    padding-top: 150px;
  }
  .t-panel {
    font-size: 2.8em;
  }
  .start-a-project-btm {
    width: 50%;
  }
  .t-bottom-row ul li {
    text-align: start;
    background-color: transparent;
    line-height: 65px;
    span {
      font-family: LotaGrotesque-Thin;
      font-size: 21px !important;
    }
  }
  video {
    width: 100% !important;
    height: 20vh !important;
  }
}
</style>