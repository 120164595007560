<template>
  <div class="t-container">
      <div class="t-work-msg-container">
          <h1 class="total-works-header-msg">
            The work we do,<br>
            and the people we help.
          </h1>
      </div>
      <div class="t-work-inner-container">
          <div class="t-row-1">
            <div class="t-work-hero-class-1">
                <img src="https://www.total.dev/assets/TildaLabsMiningContract.png" alt="" srcset="">
                <div class="info">
                    <router-link to="">myMiningContract </router-link> -  <span>Creating a new platform<br>
                    to tap into cryptocurrency mining.</span>
                </div>
            </div>
            <div class="t-work-hero-class-2">
                <img src="https://www.total.dev/assets/TheOnlineHelitronScanner.png" alt="" srcset="">
                <div class="info">
                    <router-link to="/Projects/HelitronScanner">The Online Helitron Scanner</router-link> -  <span>New visual system<br>
                    to process and display analyzed genetic code.</span>
                </div>
            </div>
          </div>
          <div class="t-row-2">
            <div class="t-work-hero-class-3">
                <img src="https://www.total.dev/assets/Medical-Site.png" alt="" srcset="">
                <div class="info">
                    <router-link to="/Projects/Medical">Custom Medical Site</router-link> -  <span>Custom product page<br>
                    for medical aparatus.</span>
                </div>
            </div>
          </div>
          <h2>Mobile Apps</h2>
          <div class="t-row-3">
            <div class="t-work-hero-class-4">
                <img src="https://www.total.dev/assets/exo.png" alt="" srcset="">
                <div class="info">
                    <router-link to="/Exo">Exo</router-link> -  <span>A decentralized<br>
                    file storage solution.</span>
                </div>
            </div>
            <div class="t-work-hero-class-5">
                <img src="https://www.total.dev/assets/subwav.png" alt="" srcset="">
                <div class="info">
                    <router-link to="">SubWav</router-link> -  <span>Custom music streaming<br>
                    platform.</span>
                </div>
            </div>
          </div>
      </div>
      
      <div class="t-panel-work" data-color="white">
          <h2>Have something in mind?</h2>
          <div class="start-a-project-btm" @click="boundries"><span>Get Started</span></div>
          <div class="t-bottom-row">
              <div class="t-col-one">
                  <ul>
                      <li><div><img src="./../assets/logo-transparent.svg" alt="Total Developers" srcset=""></div></li>
                      <li style="margin-top:-79px">Creating it all</li>
                  </ul>
              </div>
              <div class="t-col-two">
                    <ul>
                      <li>Email - <br><span>us@total.dev</span></li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
export default {
      metaInfo() {
        return {
            title: "Total.",
            meta: [
                {
                name: "description",
                content: "Creating the world's most exiting digital experiences.",
                },
                {
                name: "keywords",
                content: "crypto mining, helitron scanner, parker labs, medical, research"

                }
            ],
        };
    },
    mounted(){
        
        const tl = this.renderWorkAnimationLoadUp();
        tl.call(() => {
            tl.kill();
            tl.restart();
        });
    },
    methods:{
        detectMobileDevice: function(){
            try{
                if(document.createEvent("TouchEvent")) {
                    document.querySelector(".total-header-msg").style.paddingTop = "calc(17px*0.9)";
                    document.querySelector(".t-inner-container").style.paddingTop = "0px";
                }
            } catch(e){ 
                return false; 
            }
        },
        renderWorkAnimationLoadUp:function() {
            this.detectMobileDevice();
            this.renderStyling();
            return gsap.from('.total-works-header-msg', {y: -200, duration: 1.27});

        },
        renderStyling: function() {
            document.documentElement.style.setProperty('--mobilePaint', "#000");
            document.querySelector("body").style.backgroundColor = "#fff";
            document.querySelectorAll(".t-section a").forEach((attr)=>attr.style.color="#000")
            document.querySelector(".t-header").style.backgroundColor = document.querySelector('body').style.backgroundColor;
        },
        boundries: function(){ 
            this.$router.push({
                name: 'Start',
            });
            
        },
    }

}
</script>

<style lang="scss">
@font-face {
  font-family: "LotaGrotesque";
  src: url('./../assets/Lota-Grotesque-Regular.woff');
}
@font-face {
  font-family: "LotaGrotesque-Thin";
  src: url('./../assets/Lota-Grotesque-Thin.woff');
}
:root {
  --paint: #000;
  --paintOff: #fff;
  --mobilePaint: #fff;
}
h2 {
    font-family: "LotaGrotesque";
    color: #000;
}
.t-name{
    color: var(--mobilePaint);
}
.t-work-msg-container{
    max-width: 1280px;
    margin: 0 auto;
}
.total-works-header-msg{
    padding-top: calc(40px*0.9);
    color: var(--paint) !important;
    font-size: 72px;
    font-family: LotaGrotesque;
    text-align: start;
}

.t-work-inner-container{
    padding-top: calc(100px*0.9);
    max-width: 1280px;
    margin: auto;
    
    .t-row-1{
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        width:100%;
        .t-work-hero-class-1{
            padding: 12px;
            .info{
                text-align: start;
                color:var(--paint);
                font-family: LotaGrotesque;
                font-size: 1.5em;
                margin-top:12px;
                line-height: 1.5em;
                a{
                    color:var(--paint);
                
                }
                span{
                    font-family: LotaGrotesque-Thin;
                }
                img{
                    max-width: 50%;
                    background-color: #dfe6e9;
                }
            }
        }
        .t-work-hero-class-2{
            padding: 50px;
            display: flex;
            flex-direction: column;
            align-items: end;
            .info{
                text-align: start;
                color:var(--paint);
                font-family: LotaGrotesque;
                font-size: 1.5em;
                margin-top:12px;
                line-height: 1.5em;
                a{
                    color:var(--paint);

                }
                span{
                    font-family: LotaGrotesque-Thin;
                }
            }
            img{
                background-color: #dfe6e9;
            }
        }
        img{
            max-width: 100%;
        }
    }
    .t-row-2{
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        width:100%;
        .t-work-hero-class-3{
            padding: 50px;
            display: flex;
            flex-direction: column;
            .info{
                text-align: start;
                color:var(--paint);
                font-family: LotaGrotesque;
                font-size: 1.5em;
                margin-top:12px;
                line-height: 1.5em;
                a{
                    color:var(--paint);

                }
                span{
                    font-family: LotaGrotesque-Thin;
                }
            }
            img{
                background-color: #27AC9D;
            }
        }
        img{
            max-width: 50%;
        }
    }
    .t-row-3{
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        width:100%;
        .t-work-hero-class-4{
            padding: 12px;
            .info{
                text-align: start;
                color:var(--paint);
                font-family: LotaGrotesque;
                font-size: 1.5em;
                margin-top:12px;
                line-height: 1.5em;
                a{
                    color:var(--paint);
                
                }
                span{
                    font-family: LotaGrotesque-Thin;
                }
                img{
                    max-width: 50%;
                    background-color: #dfe6e9;
                }
            }
        }
        .t-work-hero-class-5{
            padding: 50px;
            display: flex;
            flex-direction: column;
            align-items: end;
            .info{
                text-align: start;
                color:var(--paint);
                font-family: LotaGrotesque;
                font-size: 1.5em;
                margin-top:12px;
                line-height: 1.5em;
                a{
                    color:var(--paint);

                }
                span{
                    font-family: LotaGrotesque-Thin;
                }
            }
            img{
                background-color: #dfe6e9;
            }
        }
        img{
            max-width: 100%;
        }
    }
}
.t-panel-work{
    color: #000;
    font-size: 72px;
    font-family: LotaGrotesque;
    padding-top: calc(230px*0.9);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}
@media screen and (max-width: 600px) {
    .t-bottom-row {
        flex-direction: column;
        align-items: center;
        br{content:' ';}

    }
    .total-works-header-msg{
        padding: 11px;
        font-size: 35px;
        font-family: LotaGrotesque-Thin;
    }
    .t-work-inner-container{
        .t-row-1{
            flex-direction: column;
            .t-work-hero-class-1{
                width: 90%;
                padding:0;
                margin: 6px auto;
            }
            .t-work-hero-class-2{
                width: 90%;
                padding:0;
                margin: 6px auto;
            }
        }
    }
    .b-a {
        transition: all .25s;
        content: "";
        position: absolute;
        right: 0;
        height: 2px;
        width: 30px;
        background-color: black !important;
        }
        .menu-collapsed{
            display: block;
            transition: all .25s;
            position: fixed;
            top: 21px;
            right:0px;
            height: 36px;
            width: 36px;
            z-index: 1;
            cursor: pointer;
        .bar {
            background-color:var(--mobilePaint);
            &:before {
                background-color:var(--mobilePaint);
            }
            &:after {
                background-color:var(--mobilePaint);
            }
        }
    }
    .b-a-expanded {
        transition: all .25s;
        top: -0px;
        }
        .menu-expanded {
            transition: all .25s;
            text-align: center;
            height:98vh;
            width: 100%;
            border-radius: 0px;
            top: 0;
            right: 0;
            background-color: var(--paintOff) !important;
        .bar {
            background-color: transparent;
            transition: all .25s;
            margin: 6px;
            &:before {
                @extend .b-a-expanded;
                transform: rotate(45deg);
                background-color:var(--paint);
                z-index: 100;
            }
            &:after {
                @extend .b-a-expanded;
                transform: rotate(-45deg);
                background-color:var(--paint);
                z-index: 100;
            }
        }
        li{
            background-color:var(--paintOff);
            a{
                color: var(--paint);
            }
        }
        .re-open{
            top:21px
        }
    }
    .t-bottom-row {
        flex-direction: column;
        align-items: center;
        br{content:' ';}

    }
    .t-panel-work{
        font-size: 1.8em !important;
    }
}
</style>